.custom-modal-window.modal.fade .modal-dialog {
    -webkit-transform: translate3d(-0%, 0, 0);
    transform: translate3d(-0%, 0, 0);
}

.custom-side-panel-dialog.modal-dialog {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 25vw;
    height: 100vh;
    margin: 0;
    .modal-content {
        height: 100%;
    }
}

.side-panel {
    width: 100%;
    height: 100%;
    .panel-header {
        @include justifyBetween;
        height: 6vh;
        border-bottom: 0.1rem solid $gray-650;
        padding: 1rem;
        .header {
            font-size: 1rem;
        }
        .clear {
            font-size: 1.1rem;
        }
    }
    .panel-body {
        height: 93vh;
        overflow-y: auto;
    }
    .panel-body2 {
        height: 87vh;
        overflow-y: auto;
        padding: 1rem;
        .col-12 {
            padding: 0;
        }
    }
    .panel-footer {
        @include justifyAround;
        height: 5vh;
        border-top: 0.1rem solid $gray-650;
        padding: 1rem;
    }
}

@media screen and (orientation: portrait) {
    .custom-side-panel-dialog.modal-dialog {
        width: 45vw;
    }
}

@media screen and (max-width: 480px) and (orientation: portrait) {
    .custom-side-panel-dialog.modal-dialog {
        width: 75vw;
    }
}

@media screen and (orientation: landscape) {
    .custom-side-panel-dialog.modal-dialog {
        width: 30vw;
    }
    .side-panel {
        .panel-header {
            height: 8vh;
        }
        .panel-body {
            height: 91vh;
        }
    }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
    .custom-side-panel-dialog.modal-dialog {
        width: 40vw;
    }
    .side-panel {
        .panel-header {
            height: 10vh;
        }
        .panel-body {
            height: 89vh;
        }
    }
}