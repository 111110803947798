.form-control:focus{
    border:0.3rem solid $inputBorder;
}

.form-control:focus, 
.btn:focus{
    box-shadow: none !important;
    outline: none !important;
};

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray-700;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: $input;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px $input;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  ::placeholder{
    color: $gray-800;
}

.filter-select{
  width: 20vw !important;
  margin-left: auto;
}

.form-group{
  padding-left: 0;
}

.input-group{
  height: 4vh;

  .input-group-append{
      height: 100%;

      .btn{
          padding: 0 0.5rem;
      }
  }
}

input[type=checkbox]{
  height: initial !important;
}

input{
  height: 100% !important;
}

@media screen and (max-width: 480px) and (orientation: portrait){
  .switch {
    width: 35px;
    height: 21px;
  }
  
  .slider:before {
    height: 16px;
    width: 16px;
    left: 2.5px;
    bottom: 2.5px;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }
}

@media screen and (max-height: 480px) and (orientation: landscape){
  .switch {
    width: 40px;
    height: 21px;
  }
  
  .slider:before {
    height: 16px;
    width: 16px;
    left: 2.5px;
    bottom: 2.5px;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }
}

.form-check-input{
  margin-top: 0 !important;
}

.form-check-label{
  margin-left: 0.5rem;
}
.dp-input-container {
  height: 100%;
  input {
      height: 100% !important;
      width: 100% !important;
      border: none;
      outline: none;
  }
  input:focus {
      outline: none !important;
  }
}

.dp-open+div {
  top: calc(1.5em + .75rem + 2px) !important;
  left: 0 !important;
  width: 100%;
}

dp-day-time-calendar,
dp-day-calendar {
  width: 100%
}

dp-day-calendar.dp-material .dp-calendar-weekday,
dp-day-calendar .dp-calendar-day {
  width: calc(100%/7) !important;
}

dp-day-calendar.dp-material .dp-calendar-weekday {
  color: #17a2b8 !important;
  font-style: italic;
}

dp-day-calendar.dp-material .dp-calendar-day,
dp-day-calendar.dp-material .dp-calendar-month {
  border-radius: 0 !important;
}

.custom-ng-select{
  height: 4vh;
  .ng-select-container{
    min-height: 0 !important;
  }
}
.custom-ng-select.ng-select-single .ng-select-container{
  height: 100% !important;
}

