/*FLEX MIXINS*/
@import "_variables.scss";

@mixin perfectCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin rowItemsVCenter {
    display: flex;
    align-items: center;
}

@mixin rowItemsHCenter {
    display: flex;
    justify-content: center;
}

@mixin colItemsHCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin colItemsVCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@mixin justifyAround {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

@mixin itemsVCenterRight{
    display: flex;
    align-items: center;
    justify-content: right;
}

@mixin justifyBetween{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@mixin itemsVCenterLeft{
    display: flex;
    align-items: center;
    justify-content: left;
}

/*SHADW MIXINS*/
@mixin shadow-1 {
    box-shadow: 0 1px 2px $black-200 !important
}

@mixin shadow-2 {
    box-shadow: 0 0.5rem 1.5rem $black-100;
}
