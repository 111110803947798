/*ALL VARIABLES WILL BE DECLARED HERE*/


/*COLOR VARIABLES*/

$body: #f0f2f5;
$copyright: rgba(250, 161, 161, 0.7);

/*SIGNAL BARS*/

$bar-background: #fafafa;
$bar-border: #f3f3f3;

/*FORM*/

$inputBorder: #80bdff;
$input: #2196F3;
$placeholder: #b1b1b1;
$btn-border: #E0E0E0;
$input-outline: #ced4da;
$primary: #FFF;
$secondary: #FFF;
$sidemenuBackground:#0e2d61;
$sidemenuText:#FFF;

/*BLACK SHADES*/

$black: #000;
$black-100: rgba(0, 0, 0, .075);
$black-200: #00000033;

/*GRAY SHADES*/

$gray-100: #F8F9FA;
$gray-200: #F0F2F5;
$gray-300: #EFEFEF;
$gray-400: #E7E2FA;
$gray-500: #E4E6EB;
$gray-600: #D3D9Db;
$gray-650: #ddd;
$gray-700: #ccc;
$gray-800: #a3a3a3;
$gray-900: #6C757D;
$gray-1000: rgba(0, 0, 0, 0.2);
$blue-100: #0D6EBE;
$warning: #F5990F;
$error: #F50F0F;

/* Common Colors*/

$white: #FFF;
$green: green;
$red: red;
$yellow: yellow;
$blue: #007bff;
$orange: orange;
$dark-blue: rgb(0, 71, 131);
$light-purple: #E7E2FA;
$dark-purple: #8D6BEA;