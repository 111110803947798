/* You can add global styles to this file, and also import other style files */


/*IMPORT ALL GLOALL SCSS*/

@import "_mixins.scss";
@import "_variables.scss";
@import "_typography.scss";
@import "_forms.scss";
@import "_sizes.scss";
@import "_cards.scss";
@import "_tables.scss";
@import "_sidepanel.scss";
html,
body {
    height: 100vh;
    width: 100vw;
    font-family: 'Karla', sans-serif;
    background-color: $body !important;
    .main-header {
        padding: 0;
        background-color: transparent;
        font-size: 0.65rem;
    }
}

;
html {
    font-size: 14px !important;
}

;
.fas {
    font-weight: 900;
}

a:hover {
    text-decoration: none !important;
}

.pointer {
    cursor: pointer;
}

.main-header {
    display: flex;
    align-items: center;
}

.badge {
    font-size: 100% !important;
}

.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

//PAGINATION
.pagination-row {
    @include rowItemsVCenter;
    justify-content: flex-end;
    margin-top: 1vh;
    ul {
        margin-bottom: 0;
        font-size: 0.9rem;
    }
}

//MAP VIEW MODALS
.custom-mapview-dialog.modal-dialog {
    height: 70vh;
    width: 80vw !important;
    max-width: 80vw !important;
    background-color: $white;
    .modal-content {
        height: 100%;
    }
}

.mapLabel {
    font-size: 13px;
    background-color: white;
    color: #000;
    padding: 0.3rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.5)!important;
    border-radius: 0.25rem;
    text-align: center;
    transform: translateY(-30%);
}

.bg-white {
    background-color: #fff;
}

//Map-InfoView
.white-background {
    background-color: #fff;
    box-shadow: 0 1px 2px #00000033 !important;
}

.vertical-center {
    margin-top: auto;
    margin-bottom: auto;
}

.gm-ui-hover-effect {
    display: none !important;
}

.gm-style .gm-style-iw-d {
    overflow: hidden !important;
}

.gm-style .gm-style-iw-c {
    padding: 0px;
}

#view-details-map {
    color: black;
    background-color: white;
    height: 15px;
    width: 15px;
}

.pac-container {
    z-index: 1050 !important;
    width: 370px !important;
    left: 450px !important;
}

.no-data {
    @include perfectCenter;
    height: 100%;
    width: 100%;
}

.breadcrumb {
    margin-bottom: 0.5rem !important;
}

.powered-by {
    @include perfectCenter;
    height: 6.5vh;
    margin-top: auto;
    position: absolute;
    bottom: 50px;
    width: 240px;
    span {
        font-weight: bold;
    }
    img {
        margin-left: 0.5rem;
    width: 80px;
    border-radius: 8px;
    }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
    body {
        font-size: 8px !important;
    }
    ;
    .main-header {
        font-size: 0.75rem !important;
    }
    .padding-left {
        padding-right: 6px !important;
    }
}

@media screen and (max-width: 480px) and (orientation: portrait) {
    body {
        font-size: 9px !important;
    }
    ;
    .padding-left {
        padding-right: 6px !important;
    }
}